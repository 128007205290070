import { Box, Button, FormControl, TextField } from "@material-ui/core";
import {
  Call,
  Facebook,
  Instagram,
  Telegram,
  WhatsApp,
  Mail,
  PhoneAndroid,
  PhoneIphone,
  LocationOnOutlined,
  Phonelink,
} from "@material-ui/icons";
import emailjs from "@emailjs/browser";
import "./index.scss";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ContactUsPage = () => {
  const form = React.useRef();
  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [message, setMessage] = useState("");
  const sendEmail = (e) => {
    e.preventDefault();
    console.log(e);
    const YOUR_SERVICE_ID = "service_np2i568";
    const YOUR_TEMPLATE_ID = "template_ex0fovm";
    const YOUR_PUBLIC_KEY = "d_TIEh1A6zvLRvkXA";
    emailjs
      .sendForm(
        YOUR_SERVICE_ID,
        YOUR_TEMPLATE_ID,
        form.current,
        YOUR_PUBLIC_KEY
      )
      .then(
        (result) => {
          toast.success(
            `Thank you for your message ${name}! We will get back to you as soon as possible!`,
            {
              position: "top-center",
              hideProgressBar: false,
              autoClose: false,
              closeOnClick: true,
              pauseOnHover: true,
              toastId: 9,
            }
          );
          setName("");
          setMessage("");
          setEmailAddress("");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <Box className="contact-us-page-container">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.5970965494994!2d-0.014279862210862727!3d51.50226072653282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487602bae6d401d3%3A0xcc81fb35836ab8fd!2sGeorge%20Street!5e0!3m2!1sen!2suk!4v1723749986069!5m2!1sen!2suk"
        width={window.innerWidth <= 667 ? "95%" : "100%"}
        height={"500"}
        style={{
          border:
            window.innerWidth <= 667 ? "1px solid black" : "5px solid #648EB3",
          marginTop: "2rem",
        }}
        allowfullscreen={true}
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <Box className="contact-us-page-inner-container">
        <Box className="contact-us-page-left-side-content-container">
          <Box
            className={
              window.innerWidth <= 667
                ? "contact-us-page-left-side-content-mobile"
                : "contact-us-page-left-side-content"
            }
          >
            <Box className="contact-us-page-left-side-contact-info-inner-box">
              <Mail fontSize="large" />
              <h3>MESSAGE</h3>
              <span>You can get back to us via the email above.</span>
              <p style={{ display: "flex", margin: "0.5rem auto" }}>
                support@nordenex.com
              </p>
            </Box>
          </Box>
          <Box
            className={
              window.innerWidth <= 667
                ? "contact-us-page-left-side-content-mobile"
                : "contact-us-page-left-side-content"
            }
          >
            <Box className="contact-us-page-left-side-contact-info-inner-box">
              <Call fontSize="large" />
              <h3>CALL</h3>
              <span>You can reach us immediately on the following phones.</span>
              <Box style={{ display: "flex", margin: "0.5rem auto" }}>
                <p>442037578196</p>
                <p>442033764810</p>
                <p>442038666762</p>
              </Box>
            </Box>
          </Box>
          <Box
            className={
              window.innerWidth <= 667
                ? "contact-us-page-left-side-content-mobile"
                : "contact-us-page-left-side-content"
            }
          >
            <Box className="contact-us-page-left-side-contact-info-inner-box">
              <LocationOnOutlined fontSize="large" />
              <h3>VISIT</h3>
              <span>You can visit us on the following address.</span>
              <p
                style={{
                  display: "flex",
                  margin: "0.5rem auto",
                  textWrap: "wrap",
                }}
              >
                20 Water St, London E14 9QG, United Kingdom
              </p>
            </Box>
          </Box>
        </Box>
        <form
          // style={{ display: window.innerWidth <= 667 && "none" }}
          className="contact-us-page-right-side-content"
          ref={form}
          method="post"
          onSubmit={(e) => sendEmail(e)}
        >
          <h2>Get in Touch with Us</h2>
          <p>
            Our dedicated customer support is standing by to assist you 24/5
            with any support you may require.
          </p>
          <Box className="contact-us-page-right-side-inputs-container">
            <TextField
              className="edit-user-textField"
              label="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              style={{
                margin: "15px 2px",
                width: "400px",
                alignSelf: "center",
              }}
              variant="outlined"
              name="user_name"
              InputLabelProps={{
                style: { color: "white", margin: "-3% auto" },
              }}
              InputProps={{
                style: {
                  color: "white",
                },
              }}
              inputProps={{
                style: {
                  backgroundColor: "#151515",
                  border: "1px solid gray",
                  height: "50px",
                },
              }}
            />
            <TextField
              className="edit-user-textField"
              label="Email Address"
              value={emailAddress}
              onChange={(e) => {
                setEmailAddress(e.target.value);
              }}
              style={{
                margin: "15px 2px",
                width: "400px",
                alignSelf: "center",
              }}
              variant="outlined"
              name="user_email"
              InputLabelProps={{
                style: { color: "white", margin: "-3% auto" },
              }}
              InputProps={{
                style: {
                  color: "white",
                },
              }}
              inputProps={{
                style: {
                  backgroundColor: "#151515",
                  border: "1px solid gray",
                  height: "50px",
                },
              }}
            />
            <TextField
              multiline
              minRows={15}
              className="edit-user-textField"
              label="Message"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              style={{
                margin: "15px 2px",
                width: window.innerWidth <= 667 ? "100%" : "500px",
                alignSelf: "center",
              }}
              variant="outlined"
              name="description"
              InputLabelProps={{
                style: {
                  color: "white",
                },
              }}
              InputProps={{
                style: {
                  color: "white",
                },
              }}
              inputProps={{
                style: {
                  backgroundColor: "#151515",
                  border: "1px solid gray",
                  height: "150px",
                },
              }}
            />
          </Box>
          <Button
            color="inherit"
            style={{
              width: "40%",
              margin: "0 auto",
              background: "#FF6101",
              height: "7%",
              fontWeight: "bolder",
              fontSize: "1rem",
              color: "black",
            }}
            type="submit"
          >
            SEND
          </Button>
        </form>
      </Box>
      {/* <Box> */}
      {/* <h1 className="contact-us-page-header">Contact Us</h1> */}
      {/* </Box> */}
      {/* <Box className="contact-us-page-textBox">
        <h3 className="contact-us-page-h3">Reach out to us for assistance</h3>
        <Box className="contact-us-page-span">
          <span>
            Our dedicated customer support is standing by to assist you 24/5
            with any support you may require.
          </span>
        </Box>
      </Box>
      <div>
        <div className="contact-us-container">
          <div className="contact-us-panel">
            <form
              ref={form}
              method="post"
              onSubmit={(e) => sendEmail(e)}
              className="contact-us-form"
            >
              <div className="contact-us-panel-group">
                <input
                  className="contact-us-input"
                  style={{ color: "white", backgroundColor: "#037594" }}
                  type="text"
                  name="user_name"
                  required
                />
                <span className="contact-us-border"></span>
                <label style={{ color: "white" }}>Your Name</label>
              </div>

              <div className="contact-us-panel-group">
                <input
                  className="contact-us-input"
                  style={{ color: "white", backgroundColor: "#037594" }}
                  type="text"
                  required
                  name="user_email"
                />
                <span className="contact-us-border"></span>
                <label style={{ color: "white" }}>Email Address</label>
              </div>

              <div className="contact-us-panel-group">
                <textarea
                  className="contact-us-input"
                  style={{ color: "white", backgroundColor: "#037594" }}
                  type="text"
                  required
                  multiple
                  name="description"
                  rows={4}
                />
                <span className="contact-us-border"></span>
                <label style={{ color: "white" }}>Message</label>
              </div>
              <button type="submit">Send Enquiry</button>
            </form>

            <div className="contact-us-panel-footer">
              <p>
                Contact us by email?{" "}
                <a style={{ cursor: "pointer", color: "#9eb3e1" }}>
                  support@nordenex.com
                </a>
              </p>
              <p style={{ fontSize: "1.5rem", marginTop: "1rem" }}>Dial us:</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div>
                  <Call></Call>
                  <a style={{ cursor: "pointer", color: "#9eb3e1" }}>
                    +46 766760611
                  </a>
                </div>
                <div>
                  <Call></Call>
                  <a style={{ cursor: "pointer", color: "#9eb3e1" }}>
                    +46 731214782
                  </a>
                </div>
                <div>
                  <Call></Call>
                  <a style={{ cursor: "pointer", color: "#9eb3e1" }}>
                    +46 731738333
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <ToastContainer />
    </Box>
  );
};

export default ContactUsPage;
